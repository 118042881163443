<template>
    <div class="mobile-wrap">
        <div class="head-nav" id="headNav">
            <div class="head-nav_left">
                <i class="logo"></i>
                <!-- <span class="logo-name">云墓园</span> -->
            </div>
            <div class="nav-cooperate">合作联系：15626258625</div>
            <div class="head-nav_right" @click="openMenu"></div>          
        </div>
         <van-popup v-model:show="showPopup" position="top" :teleport="container">
            <div class="popup-wrap">
                <template v-for="(item, index) in navList" :key={index}>
                    <a href="javascript:void(0)" class="list-item"  @click="handleClick(item)">{{item.name}}</a>
                </template>
            </div>
        </van-popup>
        <div class="banner-bg" id="index">
            <div class="banner-con">
                <div class="banner-title"></div>
                <div class="banner-text">
                    <span>为故人建一个永恒的生命纪念馆</span><br/>
                    <span>您也一定有很多话想对他说</span>
                </div>              
            </div>
            <div class="banner-scan">
                <img class="scan-code" :src="subcribe_code_url" alt="">
                <span>微信扫码关注“云上思念”公众号</span>
                <span>为故人创建永久纪念馆</span>
            </div>
            <div class="banner-bottom">
                <div class="flex-item">
                    <span class="item-num">500w</span>
                    <span class="item-name">纪念馆</span>
                </div>
                <div class="flex-item">
                    <span class="item-num">4320w</span>
                    <span class="item-name">祈福</span>
                </div>
                <div class="flex-item">
                    <span class="item-num">500w</span>
                    <span class="item-name">用户使用</span>
                </div>
                <div class="flex-item">
                    <span class="item-num">200w</span>
                    <span class="item-name">树洞</span>
                </div>
            </div>
        </div>
        <!-- 平台介绍 -->
        <div class="platform-introduction" id="platformIntroduction">
            <div class="column-title">平台介绍</div>
            <div class="column-title-tips">生命的终止不过一场死亡，死的意义不过在于重生或永眠，死亡不是失去生命，而是走出时间</div>
            <div class="int-banner">
                <img class="int-banner_img1" src="http://static.yunmuyuan.net//ymy/uploads/20221024/47371a5a8d03b65619b4f3e4a446ac83.jpg" alt="">
                <img class="int-banner_img2" src="http://static.yunmuyuan.net//ymy/uploads/20221024/663560b6b83114e1a7a1609afa5cd02f.jpg" alt="">
                <img class="int-banner_img3" src="http://static.yunmuyuan.net//ymy/uploads/20221024/e2cc8bdcc6ee4456ae04f1541a870304.jpg" alt="">
            </div>
            <div class="int-subtitle">云上思念 | 新一代网上纪念平台</div>
            <div class="int-text">云上思念是为全球用户免费提供网上纪念服务的平台，用户可以免费为故人创建网上纪念馆，书写故人生平、上传影像、发表追忆文章、在线祈福留言等，永久保存故人的数字遗产。随时随地进行祈福缅怀，同时也可以邀请亲朋好友加入亲友团，共同缅怀纪念，让生命以另一种形式延续！我们一起传递生命的故事，传递孝爱。</div>
            <div class="int-scan">
                <div class="scan-code"><img :src="subcribe_code_url" alt=""></div>
                <div class="scan-text">
                    <span>扫码进入云上思念平台</span>
                    <span>为故人创建纪念馆</span>
                    <a href="http://m.yunmuyuan.net/">http://m.yunmuyuan.net/</a>
                </div>
            </div>

            <div class="int-flex-wrap">
                <div class="int-flex-item van-hairline--surround">
                    <img class="icon" src="../../assets/h5/image/icon_01.png" alt="">
                    <span class="item-name">免费建馆</span>
                    <span class="item-text">实现一键建馆，后期可以逐步完善资料</span>
                </div>
                <div class="int-flex-item van-hairline--surround">
                    <img class="icon" src="../../assets/h5/image/icon_02.png" alt="">
                    <span class="item-name">网上纪念</span>
                    <span class="item-text">提供了丰富的场景，随时随地祈福缅怀</span>
                </div>
            </div>
            <div class="int-flex-wrap">
                <div class="int-flex-item van-hairline--surround">
                    <img class="icon" src="../../assets/h5/image/icon_03.png" alt="">
                    <span class="item-name">星星守护</span>
                    <span class="item-text">为故人创建一颗星星，让爱守护你</span>
                </div>
                <div class="int-flex-item van-hairline--surround">
                    <img class="icon" src="../../assets/h5/image/icon_04.png" alt="">
                    <span class="item-name">心灵树洞</span>
                    <span class="item-text">思念情感的寄托和倾诉，存放心事，寄存秘密</span>
                </div>
            </div>
        </div>
        <!--  -->
        <!-- 平台服务 -->
        <div class="platform-sever" id="platformSever">
            <div class="column-title">平台服务</div>
            <div class="column-title-tips">死亡不是在停止呼吸的那一刻，而是在最后一个记得你的人 离开这个世界，当你彻底被世人遗忘，才是真正的“死亡”</div>
            <div class="sever-class">
                <img class="sever-class_img" src="../../assets/h5/image/icon_05.png" alt="">
                <span class="sever-class_title">免费保存 传承后代</span>
                <span class="sever-class_text">云上思念网上纪念馆可以永久保存文字、照片、视频等，方便亲友随时查看、祈福，让子孙后代了解祖辈的故事。</span>
            </div>
            <div class="sever-class">
                <img class="sever-class_img" src="../../assets/h5/image/icon_06.png" alt="">
                <span class="sever-class_title">随时随地 一键祈福</span>
                <span class="sever-class_text">用户可以在云上思念网上纪念馆随时随地对故人进行深切缅怀，表达爱意，不受时间地点限制。</span>
            </div>
            <div class="sever-class">
                <img class="sever-class_img" src="../../assets/h5/image/icon_07.png" alt="">
                <span class="sever-class_title">满足仪式感 如临其境</span>
                <span class="sever-class_text">人性化的界面设计，精致的纪念展示效果，满足用户的纪念仪式感需求，虽身在千里之外纪念如在身边。</span>
            </div>
            <!-- <div class="sever-class">
                <img class="sever-class_img" src="../../assets/h5/image/icon_08.png" alt="">
                <span class="sever-class_title">墓园合作 代客祈福</span>
                <span class="sever-class_text">提供墓园单位入驻和个性服务，用户在清明、下元、冬至、忌日等节日线上下单，委托墓园单位代客祈福。</span>
            </div> -->
        </div>
        <!--  -->
        <!-- 合作加盟 -->
        <div class="tomb" id="cooperate">
            <div class="column-title">合作加盟</div>
            <div class="column-title-tips">成功助力多家墓园、机构实现业务增长。技术服务全面覆盖线上祭扫、殡仪、墓园等相关领域</div>
            <div class="tomb-flex" style="margin-top: 30px">
                <div class="flex-item" style="height:160px" v-for="(item, index) in cemeteryList" :key={index}>
                    <img :src="item.cover_img_url" alt="">
                    <div class="flex-item-bottom">{{item.name}}</div>
                </div>
            </div>
        </div>
        <!--  -->
        <!-- 纪念馆 -->
        <div class="memory-hall" id="memory">
            <div class="column-title">纪念馆</div>
            <div class="column-title-tips">你生来万丈光芒，而离开这世间，不过就是踏上了走向星辰的路</div>
            <div class="memory-swiper">
                <swiper
                :modules="modules"
                :slides-per-view="3"
                :space-between="-40"
                :loop="true"
                navigation
                :pagination="{ clickable: true }"
                >
                    <swiper-slide v-for="(item, index) in tombList" :key="index" @click="handleClickSwiper(item)">
                        <img :src="item.head_img_url" />
                    </swiper-slide>
                </swiper>
            </div>
        </div>
        <!--  -->
        <!-- 智慧陵园管理系统 -->
        <!-- <div class="wisdom-systom" id="wisdomSystem">
            <div class="column-title">智慧陵园管理系统</div>
            <div class="wistom-flex">
                <div class="flex-item">
                    <img src="http://static.yunmuyuan.net//ymy/uploads/20220105/ba5bd94b705fe61e91aded7bd6987f82.png" alt="">
                    <span>功能个性化</span>
                    <span>更灵活</span>
                    <img src="http://static.yunmuyuan.net//ymy/uploads/20220105/bc4999101798fd34f138082b9f062728.png" alt="">
                    <span>云存储服务</span>
                    <span>更安全</span>
                </div>
                <div class="flex-item">
                    <img src="http://static.yunmuyuan.net//ymy/uploads/20220105/0977f9414e19fcc00f0e62e5d66edd31.png" alt="">
                    <span>随时登录操作</span>
                    <span>更便捷</span>
                    <img src="http://static.yunmuyuan.net//ymy/uploads/20220105/185b929d701593a3492a64cb38c8aa9f.png" alt="">
                    <span>提高管理效率</span>
                    <span>更高效</span>
                </div>
            </div>
        </div> -->
        <!--  -->

        <div class="gray-wrap">
            <div class="gray-flex" style="margin-bottom: 15px">
                <div class="flex-item">
                    <div class="big-num">98w</div>
                    <div class="item-right">
                        <img src="../../assets/h5/image/icon_09.png" alt="">
                        <span>网上</span>
                        <span>纪念馆</span>
                    </div>
                </div>
                <div class="flex-item">
                    <div class="big-num">800w</div>
                    <div class="item-right">
                        <img src="../../assets/h5/image/icon_10.png" alt="">
                        <span>累计</span>
                        <span>用户</span>
                    </div>
                </div>
            </div>
            <div class="gray-flex">
                <div class="flex-item">
                    <div class="big-num">600w</div>
                    <div class="item-right">
                        <img src="../../assets/h5/image/icon_11.png" alt="">
                        <span>次</span>
                        <span>祝福</span>
                    </div>
                </div>
                <div class="flex-item">
                    <div class="big-num">872</div>
                    <div class="item-right">
                        <img src="../../assets/h5/image/icon_12.png" alt="">
                        <span>服务商</span>
                    </div>
                </div>
            </div>
        </div>
        <!--  -->
        <!-- 联系我们 -->
        <div class="contact-wrap" id="contactUs">
            <div class="column-title" style="text-align: left;margin-bottom: 10px">联系我们</div>
            <div class="contact-text">云上思念是由深圳市微友家园科技有限公司打造的一款数字化信息结合的线上纪念平台。微友家园专注于互联网+传统信息服务领域，7年来为上千家企业提供咨询服务，经多轮融资，资本实力雄厚，已成为国内同行业的领导品牌。</div>
            <!-- <div class="contact-text">于疫情之初推出云上思念平台，致力于为传统殡葬行业提供一站式网络祈福解决方案，为用户提供以线上纪念馆为基础的专业网络祈福服务和在线丧葬服务。</div> -->
            
            <div class="contact-text">以弘扬中华传统祭祖文化，推动绿色殡葬改革为理念，开辟出线上和线下联动的创新纪念模式，将传统殡葬文化与科技事业完美结合，用务实笃行，把中国传统文化向着生态创新的方向不断推进。</div>
            <div class="contact-text">云上思念平台致力于为传统行业提供一站式网上纪念解决方案，为用户提供以线上纪念馆为基础的专业网络祈福服务。</div>
            <!-- <div class="contact-text">开辟出线上和线下联动的创新纪念模式，用务实笃行，把中国传统文化向着生态创新的方向不断推进。</div> -->
            <div class="contact-text mb-0">联系方式</div>
            <div class="contact-text mb-0">联系电话：15626258625</div>
            <div class="contact-text mb-0">客服电话：15626258625</div>
            <div class="contact-text mb-0">地址：深圳市宝安区西乡街道旭生大厦2215-2217</div>
            <div class="contact-text mb-0"><a class="ICP text " target="_blank" href="https://beian.miit.gov.cn/"><img src="https://cdn.zhuguanghui.com/img/beian2.png" width="14"> 粤ICP备17155564号</a></div>
            <div class="contact-text mb-0"><a target="_blank" class="ICP text" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030602007191">
                <img src="https://cdn.zhuguanghui.com/img/beian1.png" width="14"> 粤公网安备44030602007191号
            </a></div>

            <div class="scan-code">
                <img  :src="subcribe_code_url" alt="">
            </div>
            <span class="contact-text mb-12">云上思念公众号</span>
        </div>
        
         <div class="fix-kefu" @click="handleKefu">
            <img src="../../assets/h5/image/kefu.png" alt="">
        </div>
    </div>
</template>

<script>
import axios from '../../request/http.js';
import api from '../../request/api.js'
import { Icon, Popup } from 'vant';
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import { Navigation, Pagination, A11y } from "swiper";

import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";
// import "swiper/swiper-bundle.min.css"; //导入全部css

export default {
    name: 'mobileIndex',
    components:{
        [Icon.name]: Icon,
        [Popup.name]: Popup,
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            navList: [
                {name: '首页', id: 'index'},
                {name: '平台介绍', id: 'platformIntroduction'},
                {name: '平台服务', id: 'platformSever'},
                {name: '合作加盟', id: 'cooperate'},
                {name: '纪念馆', id: 'memory'},
                // {name: '智慧陵园', id: 'wisdomSystem'},
                {name: '联系我们', id: 'contactUs'}
            ],
            showPopup: false,
            modules: [Navigation, Pagination, A11y],
            tombList: [],
            cemeteryList: [],
            home_code_url: '',
            subcribe_code_url: '',
        }
    },
    setup() {
        let container = document.querySelector('#headNav');     
        return {
            container
        }
    },
    mounted() {
        this.getIndexInfo()
    },
    methods:{

        openMenu() {
            this.showPopup = true
        },
        handleClick(item) {
            this.showPopup = false
            const returnEle = document.querySelector("#" + item.id);
            if (returnEle) {
                returnEle.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
            }
        },
        handleClickSwiper(item) {
            window.location.href = `http://m.yssn.cn/#/main/memory/${item.id}`
        },
        // cemeteryDetail(item) {
        //     window.location.href = `http://m.yunmuyuan.net/#/cemetery/detail/${item.id}`
        // },
        getIndexInfo() {
            axios.axiosGet(api.getIndexInfo, {}).then(res =>{
                // console.log(res)
                this.tombList = res.data.tomb
                this.cemeteryList = res.data.cemetery
                this.home_code_url = res.data.info.home_code_url
                this.subcribe_code_url = res.data.info.subcribe_code_url
            })
            .catch(err =>{
                console.log(err)
            })
        },
        handleKefu() {
            window.location.href = "https://yzf.qq.com/xv/web/static/chat/index.html?sign=37ef9b97d07d03c0214598bf1bb0b0349f449d344f0ab92d0b5463290725b9cb22ca51c2a02a8ebb5189ece701bc127d21ea608c"
        }
    }
}
</script>
<style lang="less" scoped>
    .mobile-wrap{
        .column-title{
            font-size: 20px;
            color: #333;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            text-align: center;
        }
        .column-title-tips{
            text-align: center;
            margin-top: 10px;
            font-size: 12px;
            color: #666;
        }
        .head-nav{
            width: 100%;
            height: 50px;
            position: fixed;
            left: 0;
            top: 0;
            // line-height: 50px;
            background-color: #090100;
            padding: 0 15px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            z-index: 2022;
            .nav-cooperate{
                font-size: 12px;
                color: #fff;
                font-weight: 500;
                position: absolute;
                right: 49px;
            }
            &_left{
                display: flex;
                align-items: center;
                .logo{
                    width: 102px;
                    height: 22px;
                    background: url(//static.yssn.cn//ymy/uploads/20240522/666afcca4e2950de10bd82ddb5dde3ca.png) no-repeat;
                    background-size: 100% 100%;
                    display: inline-block;
                    margin-right: 8px;
                }
                .logo-name{
                    color: #fff;
                    font-size: 18px;
                    font-weight: 600;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    letter-spacing: 2px;
                }
            }
            &_right{
                width: 19px;
                height: 16px;
                background: url(http://static.yunmuyuan.net//ymy/uploads/20220105/7ceaa6f114894093ceee32a9b6e8a1ba.png) no-repeat;
                background-size: 100% 100%;

            }
        }
        .popup-wrap{
            padding: 25px 0 0 30px;
            box-sizing: border-box;
            .list-item{
                margin-bottom: 27px;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                text-decoration: none;
                display: block;
            }
        }
        .banner-bg{
            width: 100%;
            height: 553px;
            margin-top: 50px;
            position: relative;
            background: url(http://static.yunmuyuan.net//ymy/uploads/20220106/857ac84a73a0cc8888e4fe16e37d1fd4.png)no-repeat;
            background-size: 100% 100%;
            .banner-con{
                position: absolute;
                left: 30px;
                top: 40px;
                .banner-title{
                    width: 195px;
                    height: 111px;
                    background: url(http://static.yunmuyuan.net//ymy/uploads/20220105/eaacb1393d704fad1b2d92ccba0b1f47.png)no-repeat;
                    background-size: 100% 100%;
                }
                .banner-text{
                    margin-top: 30px;
                    color: #fff;
                    font-size: 16px;
                    font-family: PingFangSC-Light, PingFang SC;
                    font-weight: 300;
                    span{
                        line-height: 2;
                    }
                }
            }
            .banner-scan{
                position: absolute;
                left: 50%;
                bottom: 115px;
                transform: translateX(-50%);
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                .scan-code{
                    width: 110px;
                    height: 110px;
                    margin-bottom: 10px;
                }
                span{
                    color: #fff;
                    font-size: 13px;
                    // font-weight: 400;
                    font-family: PingFangSC-Regular, PingFang SC;
                    line-height: 18px;
                }
            }
            .banner-bottom{
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 67px;
                background: rgba(255, 255, 255, 0.2);
                padding: 0 23px;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .flex-item{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    color: #fff;
                    .item-num{
                        font-size: 16px;
                        font-weight: 400;
                        position: relative;
                        margin-bottom: 5px;
                        &:after{
                            content: '+';
                            font-size: 14px;
                            font-weight: 400;
                            position: absolute;
                            right: -10px;
                            top: -5px;
                        }
                    }
                    .item-name{
                        font-size: 12px;
                        font-weight: 300;
                    }
                }
            }
        }

        .platform-introduction{
            width: 100%;
            background: #fff;
            padding: 50px 15px 0 15px;
            box-sizing: border-box;
            .int-banner{
                height: 214px;
                margin: 30px 0;
                padding: 0 40px 0 50px;
                box-sizing: border-box;
                position: relative;
                img{
                    position: absolute;
                    top: 0;
                }
                &_img1{
                    width: 107px;
                    left: 50px;
                    z-index: 1;
                }
                &_img2{
                    width: 114px;
                    left: 120px;
                    top: -7px !important;
                    z-index: 2;
                }
                &_img3{
                    width: 107px;
                    right: 40px;
                    z-index: 1;
                }
            }
            .int-subtitle{
                color: #e4803c;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                text-align: left;
                margin-bottom: 19px;
            }
            .int-text{
                font-size: 14px;
                font-family: PingFangSC-Light, PingFang SC;
                font-weight: 300;
                color: #333333;
            }
            .int-scan{
                margin: 15px 0 30px 0;
                display: flex;
                .scan-code{
                    width: 65px;
                    height: 65px;
                    margin-right: 10px;
                    img{
                        width: 100%;
                        display: block;
                    }
                }
                .scan-text{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    color: #333;
                    font-size: 14px;
                }
            }

            .int-flex-wrap{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 15px;
                .van-hairline--surround::after{
                    border-radius: 10px;
                }
                .int-flex-item{
                    width: 47.8%;
                    // border-radius: 10px;
                    padding: 18px 16px 20px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .icon{
                        width: 25px;
                        height: 25px;
                    }
                    .item-name{
                        font-size: 14px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #333333;
                        margin: 8.5px 0 5px 0;
                    }
                    .item-text{
                        font-size: 12px;
                        font-family: PingFangSC-Light, PingFang SC;
                        font-weight: 300;
                        color: #666666;
                        text-align: center;
                    }
                }
            }
        }

        .platform-sever{
            width: 100%;
            background: #fff;
            padding: 60px 15px 0 15px;
            box-sizing: border-box;
            .sever-class{
                margin-top: 27px;
                display: flex;
                flex-direction: column;
                &_img{
                    width: 30px;
                    display: block;
                }
                &_title{
                    font-size: 16px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    margin: 6px 0;
                }
                &_text{
                    font-size: 14px;
                    font-family: PingFangSC-Light, PingFang SC;
                    font-weight: 300;
                    color: #666666;
                }
            }
        }

        .memory-hall{
            margin-top: 60px;
            box-sizing: border-box;
            .memory-swiper{
                width: 90%;
                margin: 0 auto;
                margin-top: 16px;
                text-align: center;
                border-radius: 50%;
                img{
                    width: 55px; 
                    height: 55px;
                    border-radius: 50%;
                    box-shadow: 0px 0px 9px 1px rgba(153, 153, 153, 0.44);
                    border: 5px solid #FFFFFF;
                }
                .swiper-slide-next{
                    img{
                        width: 80px !important;
                        height: 80px;
                        position: relative;
                        top: -15px;
                    }
                    
                }
            } 
        }

        .wisdom-systom{
             margin-top: 75px;
            margin-bottom: 18px;
            box-sizing: border-box;
            .wistom-flex{
                width: 100%;
                padding: 0 24px;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                .flex-item{
                    width: 42.8%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    img{
                        width: 140px;
                        display: block;
                    }
                    span{
                        font-size: 14px;
                        font-family: PingFangSC-Light, PingFang SC;
                        font-weight: 300;
                        color: #333333;
                    }
                }
            }
        }

        .tomb{
            margin-top: 85px;
            margin-bottom: 40px;
            padding: 0 15px;
            box-sizing: border-box;
            .tomb-flex{
                // margin-bottom: 15px;
                // display: flex;
                // justify-content: space-between;
                .flex-item{
                    width: 47.8%;
                    border-radius: 5px;
                    overflow: hidden;
                    position: relative;
                    display: inline-block;
                    margin-bottom: 15px;
                    margin-right: 15px;
                    &:nth-child(2n) {
                        margin-right: 0;
                    }
                    img{
                        width: 100%;
                        height: auto;
                        display: block;
                    }
                    &-bottom{
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        height: 25px;
                        line-height: 25px;
                        background: rgba(0, 0, 0, 0.6);
                        text-align: center;
                        font-size: 14px;
                        color: #fff;
                        letter-spacing: 2px;
                        font-family: PingFangSC-Light, PingFang SC;
                    }
                }
            }
        }

        .gray-wrap{
            width: 100%;
            padding: 25px;
            box-sizing: border-box;
            background: #363226;
            margin-top: 40px;
            .gray-flex{
                width: 100%;
                display: flex;
                justify-content: space-between;
                .flex-item{
                    width: 47.6%;
                    background: #565552;
                    border-radius: 5px;
                    padding: 13.5px 17px 18px 0;
                    box-sizing: border-box;
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-end;
                    .big-num{
                        font-size: 32px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #FFFFFF;  
                        margin-right: 12px;
                    }
                    .item-right{
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: flex-start;
                        img{
                            width: 25px;
                            display: block;
                            margin-bottom: 4.5px;
                        }
                        span{
                            font-size: 12px;
                            font-family: PingFangSC-Light, PingFang SC;
                            font-weight: 300;
                            color: #FFFFFF; 
                        }
                    }
                }
            }
        }

        .contact-wrap{
            width: 100%;
            background: #F5F9FC;
            padding: 40px 15px;
            box-sizing: border-box;
            .contact-text{
                font-size: 14px;
                font-family: PingFangSC-Light, PingFang SC;
                font-weight: 300;
                color: #333333;
                margin-bottom: 25px;
                line-height: 1.5;
            }
            .mb-0{
                margin-bottom: 0;
            }
            .scan-code{
                width: 89px;
                margin-top: 20px;
                img{
                    width: 100%;
                    display: block;
                }
            }
        }
        .fix-kefu{
            position: fixed;
            right: 15px;
            bottom: 20%;
            width: 50px;
            height: 50px;
            background: #999999;
            border-radius: 50%;
            padding: 11px;
            box-sizing: border-box;
            img{
                width: 27px;
                display: block;
            }
        }
    }
</style>